import React from 'react';
import bgImage2 from '../media/PIC3.png'; // assuming the image file is in the same directory as this component

export const PrivacyPolicy = () => {
    return (
        <div className='w-full bg-white py-16 px-4'>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
                <img src={bgImage2} alt="/" className="h-[400px]" />
                <div className='flex flex-col justify-center'>
                    <p className='text-[#FF8C00] font-bold '>Privacy Policy</p>
                    <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Your Privacy Matters</h1>
                    <p>
                        We value your privacy. Our Discord bot does not save any messages on the server side. All interactions and messages are processed in real-time and not stored in any form.
                    </p>
                    <h2 className='md:text-3xl sm:text-2xl text-xl font-bold py-2'>Information Collection and Use</h2>
                    <p>
                        Our bot may collect the following types of information:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Discord User ID</li>
                        <li>Server ID</li>
                        <li>Message content (temporarily for processing)</li>
                    </ul>
                    <p>
                        This information is solely used to provide the functionality of the bot and improve user experience. We do not share, sell, or distribute this information to any third parties.
                    </p>
                    <h2 className='md:text-3xl sm:text-2xl text-xl font-bold py-2'>Data Security</h2>
                    <p>
                        We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
                    </p>
                    <h2 className='md:text-3xl sm:text-2xl text-xl font-bold py-2'>User Rights</h2>
                    <p>
                        You have the right to:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Access and review the information we hold about you</li>
                        <li>Request correction of any inaccurate information</li>
                        <li>Request deletion of your data</li>
                        <li>Opt-out of data collection and usage</li>
                    </ul>
                    <p>
                        To exercise these rights, please contact us through the support channel or email provided on our website.
                    </p>
                    <h2 className='md:text-3xl sm:text-2xl text-xl font-bold py-2'>Third-Party Services</h2>
                    <p>
                        Our bot may use third-party services to enhance functionality. These services may have access to limited data as necessary for their functionality. We ensure that these third parties are compliant with relevant privacy regulations and handle your data with care.
                    </p>
                    <h2 className='md:text-3xl sm:text-2xl text-xl font-bold py-2'>Changes to This Privacy Policy</h2>
                    <p>
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                    </p>
                    <h2 className='md:text-3xl sm:text-2xl text-xl font-bold py-2'>Contact Us</h2>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>Discord: <a href="https://discord.gg/dKW7tWR5dZ">https://discord.gg/dKW7tWR5dZ</a></li>
                        <li>Email: war@currenciesbot.com </li>
                    </ul>
                    <h2 className='md:text-3xl sm:text-2xl text-xl font-bold py-2'>Gambling Policy</h2>
                    <p>
                        Our bot's currency, BotCash, can be redeemed for gift cards but cannot be used for gambling. Additionally, any currency created by the user in the bot cannot facilitate gambling if it holds real-world value.
                    </p>
                    <p>
                        Users may not use Discord to coordinate or participate in illegal gambling. Users are responsible for complying with relevant gambling laws and regulations.
                    </p>
                    <p>
                        We define illegal gambling as any gambling activities that break applicable laws and regulations. Gambling is defined as the act of placing a bet or wager involving money or something of value on an event or activity with an uncertain outcome. Our gambling policy is violated if the following criteria are met:
                    </p>
                    <ul className='list-disc pl-5'>
                        <li>There is a payment or wager of real-world value to participate (this includes betting or entry fees)</li>
                        <li>Prizes of real-world value are awarded</li>
                        <li>The outcome of the game is predominantly determined by chance</li>
                        <li>The activity is prohibited by applicable gaming laws or regulations</li>
                    </ul>
                    <p>
                        To avoid violating this policy, users should check gambling laws and regulations applicable to them and make sure they follow them. Be extra wary of participating in potentially fraudulent activities or opportunities that use or mimic gambling mechanisms, especially those involving paying or receiving financial transactions from others. Leave and report servers with illegal or illicit gambling content or activity.
                    </p>
                </div>
            </div>
        </div>
    );
};
