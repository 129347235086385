import React from 'react';
import bgImage from '../../../../media/mainPageBG.png';

export const Hero = () => {
  return (
    <div className='text-white'>
      <div className='w-full max-width-[1000px] text-center flex flex-col justify-center' style={{ 
        height: '60vh',
        position: 'relative',
      }}>
        <div style={{
          backgroundImage: `url(${bgImage})`,
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          filter: 'blur(5px)',
          zIndex: -2,
        }} />
        <h1 className='md:text-9xl sm:text-7xl text-6xl font-bold md:py-6 text-[#EEEEEE]'>
          The Ultimate Discord Economy Creator
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-2xl sm:text-1xl text-xl  py-4'>
            Take control of your discord economy with Currencies
          </p>
        </div>
      </div>
    </div>
  );
};
