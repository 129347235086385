import { Navbar } from '../common_components/Navbar';
import { Hero } from './components/Hero';
// import { Cards } from './components/Cards';
// import { Footer } from './components/Footer';
import { Analytics } from './components/Analytics';
// import { Analytics2 } from './components/Analytics2';

export const HomePage = () => 
<div>
    <Navbar />
    <Hero/>
    <Analytics />
    </div>
