import React from 'react';
import bgImage2 from '../media/PIC3.png'; // assuming the image file is in the same directory as this component

export const Analytics = () => {
return (
    <div className='w-full bg-white py-16 px-4'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img src={bgImage2} alt="/"  className="h-[400px]"/>

            <div className='flex flex-col justify-center'>
                <p className='text-[#FF8C00] font-bold '>Ultimate Economy Creator</p>
                <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>The most customizable economy bot on discord</h1>
                <p>
                Not only does the discord bot allow for multiple currencies, but the ability to create your custom economy commands with those currencies.
                </p>
                <a href="https://discord.gg/dKW7tWR5dZ">
                    <button className='bg-[#003300] text-[#FF8C00] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Add Now!</button>
                </a>
            </div>

        </div>
    </div>
);
};

