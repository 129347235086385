import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Logo from '../../../media/logo.png';
import LogoLetters from '../HomePage/media/Letters.png';

export const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

return (
    
    <div className='flex justify-between items-center h-14 mx-auto px-4 text-black bg-[#ffff]'>
        <div className='flex items-center'>
            <img className='w-[40px]' src={Logo} alt='logo' />
            <img className='w-[120px] px-1 mx-auto' src={LogoLetters} alt='Currencies' />
        </div>
        <ul className='hidden md:flex'>
            <li className='p-4'><a href="/">Home</a></li>
            <li className='p-4'><a href="/about">About</a></li>
            <li className='bg-[#f7be4b] w-[100px] rounded-md font-medium my-2 mx-auto md:mx-0 py-2 p-4'><a href="https://discord.gg/dKW7tWR5dZ">Add Now</a></li>
        </ul>
        <div onClick={handleNav} className='block md:hidden'>
            {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
        <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#ffff] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
            <div className='flex items-center justify-center w-full'>
                <img className='w-[70px]' src={Logo} alt='logo' />
                <img className='text-3xl font-bold text-black ml-2 md:w-0 w-[120px]' src={LogoLetters} alt='Currencies' />
            </div>
            <li className='p-4 border-b border-gray-600'>Home</li>
            <li className='p-4 border-b border-gray-600'><a href="/about">About</a></li>
            <li className='p-4 border-b border-gray-600'>Login</li>
        </ul>
    </div>
);
};

