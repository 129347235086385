import React from 'react';
import {Routes, Route} from 'react-router-dom';
import { HomePage } from './website/pages/HomePage/HomePage';
import { AboutPage } from './website/pages/AboutPage/AboutPage';
import GoogleAdsRedirect from './website/pages/AboutPage/components/GoogleAdsRedirect';

function App() {
return (
    <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/redirect" Component={GoogleAdsRedirect} />
    </Routes>
);
}

export default App;
