import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const GoogleAdsRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Google Ads Conversion Tracking Tag
    const script1 = document.createElement('script');
    script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-16577270034";
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16577270034');
    `;
    document.head.appendChild(script2);

    // Google Ads Conversion Event Snippet for Sign-up conversion page
    const script3 = document.createElement('script');
    script3.innerHTML = `
      gtag('event', 'conversion', {'send_to': 'AW-16577270034/Z84DCJOWhbMZEJKi1OA9'});
    `;
    document.head.appendChild(script3);

    // Get current timestamp
    const timestamp = new Date().toISOString();

    // Call Discord webhook with timestamp
    fetch('https://discord.com/api/webhooks/1245750381397409904/w78Ulp1JmX4h0_9eK3dXEnOOtmIiU7evu8DjKy6TixnnheCIHV7D6QrgJSXdeG6hiAtC', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        content: `User has added the bot through the docs page. Timestamp: ${timestamp}`,
      }),
    });

    // Redirect to Discord OAuth URL after a short delay
    const timeoutId = setTimeout(() => {
      window.location.href = "https://discord.com/oauth2/authorize?client_id=964961739714216018&redirect_uri=https://currenciesbot.com/redirect&permissions=259845786640&scope=applications.commands+bot";
    }, 3000);  // Adjust the delay as needed

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  return <p>Redirecting you to Discord, please wait 3 seconds...</p>;
};

export default GoogleAdsRedirect;
